import React from 'react';
import { Helmet } from 'react-helmet';

export default function RootLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta property="og:site_name" content="Watches Guild"/>
        <meta property="og:type" content="website"/>
        <meta name="p:domain_verify" content="403f9b7bbc5f5f5ce56d8c224baff729"/>
        <meta name="google-adsense-account" content="ca-pub-3610412713175668" />
        <script type="application/ld+json">
        {`
          {"@context":"http://schema.org","@type":"Organization","name":"Watches Guild",
          "url":"https://www.watchesguild.com","logo":"/images/logo.svg",
          "sameAs":["https://twitter.com/WatchesGuild","https://www.facebook.com/WatchesGuild",
          "https://www.instagram.com/watchesguild/","https://www.reddit.com/user/WatchesGuild", 
          "https://www.pinterest.com/watchesguild"]}
        `}
      </script>
      </Helmet>
        {props.children}
    </React.Fragment>
  );
}
